<template>
    <div class="border border-gray-400 rounded-3xl p-4">
        <div class="font-bold text-xl border-b-2 pb-4">
            <span
                class="ml-4"
            >
                Article
            </span>
            <router-link
                type="button"
                :to="{ name: 'BlogArticle' }"
                class="ml-8 w-28 px-2 py-1 rounded-t-lg text-base text-center"
                :class="{ 'bg-primary text-white': isActive('BlogArticle'), 'bg-gray-300': !isActive('BlogArticle') }"
            >
                Blog
            </router-link>
            <router-link
                type="button"
                :to="{ name: 'PressRoomArticle' }"
                class="ml-2 w-28 px-2 py-1 rounded-t-lg text-base text-center"
                :class="{ 'bg-primary text-white': isActive('PressRoomArticle'), 'bg-gray-300': !isActive('PressRoomArticle')  }"
            >
                Press Room
            </router-link>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'CrossSelling',

        methods: {
            isActive(name) {
                return this.$route.matched[2].name == name;
            }
        }
    };
</script>
